/**
 * Style variables
 */
$base-color:           #DBDBDB !default;
$primary-color:        #a1fc8f !default;
$text-color:           $base-color !default;
$placeholder-color:    $base-color !default;
$link-color:           $base-color !default;
$border:               dashed 1px rgba(219,219,219,0.9) !default;
$selection-background: rgba(219,219,219,0.99) !default;
$selection-text:       #000 !default;
$background-color:     #000 !default;


@import "base";
